import React from 'react';
import { Link } from 'react-router-dom';
//img
import { ReactComponent as Icon } from '../../../img/Info.svg';
//techniques
import { ReactComponent as Sass } from '../../../img/Sass.svg';

const Wat = () => {
  return (
    <div className="wat">
        <div className="wrapper">

          <div class="wat__content">
            <div className="wat__heading">
              <Icon/><h2>Wat doe ik</h2>
            </div>

            <div className="wat__subtext">
              <p>Als front-end developer maak ik de gebruikerskant van websites oftewel de voorkant.<br/><br/>
              Op de website zorg ik er voor dat alles perfect werkt voor de gebruiker,<br/>
              en het er ook nog eens mooi uitziet.
              <br/><br/>Bekijk anders eens naar een aantal voorbeelden via de knop hieronder.</p>
            </div>
            <Link className="portfolio_button" to="/portfolio" style={{ textDecoration: 'none' }}>
              <h3>Portfolio</h3>
            </Link>
          </div>

          <div class="wat__techniques">
            <div class="wat__techniques__header"><h2>Dit realiseer ik met behulp van de volgende technieken.</h2></div>
              <div class="wat__techniques__container">
                <div class="wat__technique" id="image"><img src="https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg" alt=""></img></div>
                <div class="wat__technique" id="image"><Sass/></div>
                <div class="wat__technique" id="image"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Javascript-shield.svg/794px-Javascript-shield.svg.png" alt=""></img></div>
                <div class="wat__technique" id="image"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1280px-React-icon.svg.png" alt=""></img></div>
                <div class="wat__technique" id="image"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Wordpress-Logo.svg/2048px-Wordpress-Logo.svg.png" id="wp" alt=""></img></div>
              </div>
          </div>

        </div>
    </div>
  )
}

export default Wat
