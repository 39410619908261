import React from 'react'

//components

import Header from './Components/Header';
import Wat from './Components/Wat';

const Home = () => {
  return (
    <div>
      <section id="headerSection">
        <Header/>
      </section>

      <section id="watSection">
        <Wat/>
      </section>
    </div>
  )
}

export default Home
