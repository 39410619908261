import './App.css';
import './css/style.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

//components
import Home from './Pages/Home/Home';
import Portfolio from './Pages/Portfolio/Portfolio';

const App = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/portfolio" component={Portfolio}></Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
