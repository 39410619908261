import React from 'react'

//components
import Nav from '../../Nav';
import Wie from './Wie';

const Header = () => {
  return (
    // entire header
    <div className="header">
      <Nav/>
      <div className="wrapper">
      <Wie/>
      </div>
    </div> 
  )
}

export default Header;
