import React from 'react'
import { Link } from 'react-router-dom';

//logo
import {ReactComponent as Logo } from '../img/Logo.svg';

const Nav = () => {
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="navbar__wrapper">
          <div className="navbar__logo">
            <Link to="/" className="navbar__logo"><h1>Poek Ligthart</h1></Link>
          </div>
          <div className="navbar__nav"> 
            <Link to="/portfolio" className="navbar__portfolio" style={{ textDecoration: 'none', color: '#012445'}}><h1>Portfolio</h1></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nav
