import React, {useState} from 'react'

//img 
import { ReactComponent as Icon } from '../../../img/user.svg'

//components
import Contact from '../../Contact';
import CloseContact from '../../closeContact';

const Wie = () => {
  const [show, setShow] = useState(false);
  const [showClose, setShowClose] = useState(false);

  function openModal() {
    setShow(true);
    setShowClose(true);
  }

  function closeModal() {
    setShow(false);
    setShowClose(false);
  }

  return (
    <div className="wie">
        <div className="wie__content">
          <div className="wie__heading">
            <Icon/><h2>Ik ben Poek Ligthart</h2>
          </div>      
          <div className="wie__subheading">
          <h1>Front-End Developer</h1>
          </div>
          <div className="wie__subtext">
            <p>Webdesign en development krijg ik mijn hele leven al van huis uit mee.
            <br></br>De passie voor front-end development is mij dan ook niet voorbij gegaan en
            heb ik de opleiding Applicatie Ontwikkelaar afgerond op het Horizon College te Alkmaar.
            <br/><br/>Stel je vraag door op de knop hieronder te klikken, ik hoor graag van u!</p>
          </div>
          <div className="contact_button" onClick={openModal}>
            <h3>Contact</h3>
          </div>
          <Contact show={show}/>
          <div onClick={closeModal}>
            <CloseContact show={showClose}/>
          </div>
        </div>
    </div>
  )
}

export default Wie
