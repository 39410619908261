import React from 'react'

//components
import Nav from '../Nav';

const Portfolio = () => {
  return (
    <div>
      <Nav/>
    </div>
  )
}

export default Portfolio
