import React from 'react'

import {ReactComponent as Close} from '../img/Close.svg'

const CloseContact = props => {
  if(!props.show){
    return null
  }

  return (
    <div class="closeButton">
      <Close/>
    </div>
  )
}

export default CloseContact
