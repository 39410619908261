import React, {useState} from 'react';
import emailjs from 'emailjs-com';

const Contact = props => {
  const [succes , setSucces] = useState("Vul alle velden hieronder in en druk daarna op de knop 'versturen' om uw bericht te verzenden.");

  if(!props.show) {
    return null
  }

  function sendEmail(e){
    e.preventDefault();

    emailjs.sendForm('service_30wzzir', 'template_njr8hzk', e.target, 'user_Ksa58nHIDQnS2aUZkp8or')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      setSucces("Uw bericht is succesvol verstuurd, er wordt z.s.m gereageerd op uw bericht.")
      e.target.reset()
  }

  return (
    <div className="contact">
      <div className="contact__form">
        <form onSubmit={sendEmail}>
          <div className="group" id="succes">
            <p>{succes}</p>
          </div>
          <div className="group">
          <label>Naam</label>
            <input type="text" placeholder="Volledige naam.." name="naam"/>
          </div>

          <div className="group">
          <label>E-mail</label>
            <input type="email" placeholder="Uw e-mailadres.." name="email"/>
          </div>

          <div className="group">
          <label>Onderwerp</label>
            <input type="text" placeholder="Onderwerp van uw bericht.." name="onderwerp"/>
          </div>

          <div className="group" id="bericht">
          <label>Bericht</label>
            <textarea placeholder="Inhoud.." name="bericht" rows="5"/>
          </div>

          <div className="group" id="button">
            <button type="submit">Versturen</button>
          </div>


        </form>
      </div>
    </div>
  )
}

export default Contact
